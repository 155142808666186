import React, { useEffect } from 'react'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import brandAttr from 'sources/images/brand-attr.jpg'

export default function BrandAttributes(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header backTo="/menu/our-brand" title="WHAT WE STAND FOR" />
      <div className={styles.wrapper}>
        <div className={styles.block}>
          <div className={styles.title}>UNCOMPROMISING APPROACH TO INNOVATION</div>
          <br />
          <ul className={styles.text}>
            <li>Tools/services that help master and elevate your skills.</li>
            <li> Efficient, responsive, precise technology as well as striking design.</li>
            <li>Tools/services that foresee your actions and adapts to them, today and tomorrow.</li>
          </ul>
        </div>
        <div className={styles.block}>
          <div className={styles.title}>RELENTLESSLY RESPONSIBLE</div>
          <br />
          <ul className={styles.text}>
            <li>
              Driving sustainability by technical innovation, built to last quality and resource efficient solutions.
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
