import React, { useEffect } from 'react'
import Header from 'components/Header'
import { VIDEOS } from 'containers/Home'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import brandVideo from 'sources/videos/BrandVideo.mp4'
import { Animated } from 'react-animated-css'
import { Link } from 'react-router-dom'

export default function BrandManifesto(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header backTo="/menu/our-brand" title="BRAND MANIFESTO" />
      <p className={styles.text}>
        We are AEG. We exist to redefine what you expect out of your household appliances, which is why we want to
        challenge the boundaries of everyday life.
        <br />
        <br />
        Since our start in Berlin 1883, we’ve never settled for good enough, and we don’t think you should have to
        either. That’s why we never compromise with innovations for your home. So you can live on your own terms, while
        also living up to the terms of the environment. And care less, to care more about the big picture.
        <br />
        <br />
        <b>CHALLENGE THE EXPECTED</b>
      </p>
    </div>
  )
}
