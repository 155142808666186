import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import ambitiousAchievers from 'sources/images/girl-menu.jpg'

export default function WhoLivesHere(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header backTo="/menu" title="THE AMBITIOUS ACHIEVERS – THOSE WHO AIM HIGH" offsetTop={-50} />

      <img src={ambitiousAchievers} className={styles.image} alt="Ambitious achievers" />

      <p className={styles.desc}>
        We are for those who are just like us. For those who strive to do things better by staying relevant and acting
        responsibly. For people attracted to brands that they can aspire to, and wanting the best of two worlds so they
        never have to compromise on their lifestyle.
        <br />
        <br />
        <Link to="/menu/who-lives-here/more">
          Explore more <span className="fa">&#xf054;</span>
        </Link>
      </p>
    </div>
  )
}
