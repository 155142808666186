import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from 'components/Header'
import { Animated } from 'react-animated-css'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import whoWeAre from 'sources/images/new-who-we-are.jpg'

export default function WhoWeAre(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header backTo="/menu/our-brand" title="WHO WE ARE" />

      <img src={whoWeAre} className={styles.whoWeAre} alt="Who we are" />

      <p className={styles.text}>
        AEG exists...
        <br />
        <br />
        …to challenge the expected without uncompromising innovation that stands out today and helps build tomorrow.
      </p>

      <Animated animationIn="fadeInUp" animateOnMount>
        <Link to="/menu/our-brand/who-we-are/brand-idea" className={styles.button}>
          Brand promise
        </Link>
      </Animated>
    </div>
  )
}
