import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import aeg from 'sources/images/aeg.png'

export default function Innovations(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header backTo="/menu" title="Innovations" />

      <img src={aeg} className={styles.aeg} alt="AEG" />

      <p className={styles.desc}>
        We have delivered groundbreaking innovations for 130 years
        {/*<Link to="/menu/innovations/innovations">*/}
        <Link to="/menu/innovations/innovations/more">
          View innovations <span className="fa">&#xf054;</span>
        </Link>
        {/*The Care Label Project & Tasteology
        <Link to="/menu/innovations/initiatives">
          View initiatives <span className="fa">&#xf054;</span>
        </Link>*/}
      </p>
    </div>
  )
}
