import React from 'react'
import { Router, Route } from 'react-router'
import { Provider } from 'react-redux'
import history from 'utils/history'
import store from 'store'
import RouteSlider from 'components/RouteSlider'

import Home from '../Home'
import Menu from '../Menu'
import OurBrand from '../OurBrand'
import WhoWeAre from '../OurBrand/WhoWeAre'
import BrandIdea from '../OurBrand/WhoWeAre/BrandIdea'
import BrandAttributes from '../OurBrand/BrandAttributes'
import BrandVideo from '../OurBrand/BrandVideo'
import Taste from '../OurBrand/Taste'
import BrandManifesto from '../OurBrand/BrandManifesto'
import WhoLivesHere from '../WhoLivesHere'
import WhoLivesHereMore from '../WhoLivesHere/More'
import Innovations from '../Innovations/'
import InnovationsPreview from '../Innovations/Innovations/Preview'
import InnovationsPreviewMore from '../Innovations/Innovations'
import Initiatives from '../Innovations/Initiatives'

import styles from './styles.module.scss'

history.replace('/')

export default function App(props) {
  return (
    <div className={styles.wrapper}>
      <Provider store={store}>
        <Router history={history}>
          <RouteSlider>
            <Route path="/" component={Home} exact />
            <Route path="/menu" component={Menu} exact />
            <Route path="/menu/our-brand" component={OurBrand} exact />
            <Route path="/menu/our-brand/who-we-are" component={WhoWeAre} exact />
            <Route path="/menu/our-brand/who-we-are/brand-idea" component={BrandIdea} exact />
            <Route path="/menu/our-brand/brand-attributes" component={BrandAttributes} exact />
            <Route path="/menu/our-brand/brand-video" component={BrandVideo} exact />
            <Route path="/menu/our-brand/taste" component={Taste} exact />
            <Route path="/menu/our-brand/brand-manifesto" component={BrandManifesto} exact />
            <Route path="/menu/who-lives-here" component={WhoLivesHere} exact />
            <Route path="/menu/who-lives-here/more" component={WhoLivesHereMore} exact />
            <Route path="/menu/innovations" component={Innovations} exact />
            <Route path="/menu/innovations/innovations" component={InnovationsPreview} exact />
            <Route path="/menu/innovations/innovations/more" component={InnovationsPreviewMore} exact />
            <Route path="/menu/innovations/initiatives" component={Initiatives} exact />
          </RouteSlider>
        </Router>
      </Provider>
    </div>
  )
}
