import React, { Component } from 'react'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'
import Slider from 'react-slick'
import cn from 'classnames'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import taste from 'sources/images/new-think-taste.jpg'
import care from 'sources/images/new-care.jpg'
import wellbeing from 'sources/images/new-wellbeing.jpg'

const slides = [
  {
    title: 'Mastery of taste',
    src: taste,
    offsetTop: 0,
    desc: (
      <p>
        We believe in pushing the boundaries of what is achievable in the kitchen for the passionate home cook.
        <br />
        <br />
        We challenge the expectation that sustainable and healthy cooking compromises on taste. The taste of tomorrow is
        refined, healthy, and sustainable, matching your ambition to create exceptional taste experiences.
      </p>
    ),
  },
  {
    title: 'Unprecedented care',
    src: care,
    offsetTop: 0,
    desc: (
      <p>
        We believe this is the time to redefine how to care for clothes responsibly.
        <br />
        <br />
        That’s why we challenge convention and create responsible innovations, enabling you to keep your wardrobe in
        perfect condition, without compromises.
      </p>
    ),
  },
  {
    title: (
      <span>
        Wellbeing starts
        <br />
        at home
      </span>
    ),
    src: wellbeing,
    offsetTop: -50,
    desc: (
      <p>
        We believe homes are much more than a place to live. Your home is a stage to be proud of and the place that
        enables you to master your ambitions.
        <br />
        <br />
        Performance beyond expectations, dynamic design and relentless responsibility create the healthy and comfortable
        home of tomorrow.
      </p>
    ),
  },
]

export default class Taste extends Component {
  state = {
    index: 0,
  }

  componentDidMount = () => {
    idleActions.set()
    this.setInterval()
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  setInterval = () => {
    if (this.interval) clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.onNextSlide()
    }, 20000)
  }

  onChangeIndex = (index, e = null) => {
    if (e) e.stopPropagation()

    this.setState({ index }, () => {
      this.slider.slickGoTo(this.state.index)
      this.setInterval()
    })
  }

  onNextSlide = () => {
    const { index } = this.state

    if (index < slides.length - 1) {
      this.onChangeIndex(index + 1)
    } else {
      // this.onChangeIndex(0)
      this.props.history.push('/menu/our-brand')
    }
  }

  onClick = () => {
    idleActions.set()
    this.onNextSlide()
  }

  render = () => {
    const { index } = this.state

    return (
      <div className={styles.container} onClick={this.onClick}>
        <Header title={slides[index].title} backTo="/menu/our-brand" offsetTop={slides[index].offsetTop || 0} />

        <Slider
          infinite={false}
          swipe={true}
          className={styles.slider}
          arrows={true}
          afterChange={this.onChangeIndex}
          ref={node => (this.slider = node)}
        >
          {slides.map((slide, i) => (
            <div className={styles.slide} key={i}>
              <img src={slide.src} alt={slide.title} />

              {slide.desc}
            </div>
          ))}
        </Slider>

        <Animated animationIn="fadeInUp" animateOnMount animationInDelay={500}>
          <div className={styles.sliderDots}>
            {slides.map((slide, i) => (
              <div
                className={cn(index === i && styles.activeDot)}
                onClick={e => {
                  this.onChangeIndex(i, e)
                  idleActions.set()
                }}
                key={i}
              />
            ))}
          </div>
        </Animated>
      </div>
    )
  }
}
