import React, { Component } from 'react'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'
import Slider from 'react-slick'
import cn from 'classnames'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import okomix from 'sources/images/okomix.jpg'
import maxisense from 'sources/images/maxisense.jpg'
import hob2hood from 'sources/images/hob-2-hood.jpg'
import boilingWaterTap from 'sources/images/boiling-water-tap.jpg'
import innovations1 from 'sources/images/Innovations-1.jpg'
import innovations2 from 'sources/images/Innovations-2.jpg'
import innovations3 from 'sources/images/Innovations-3.jpg'
import innovations4 from 'sources/images/Innovations-4.jpg'
import innovations5 from 'sources/images/Innovations-5.jpg'
import innovations6 from 'sources/images/Innovations-6.jpg'
import innovations7 from 'sources/images/Innovations-7.jpg'
import innovations8 from 'sources/images/Innovations-8.jpg'

const slides = [
  {
    title: 'WASHING MACHINES',
    src: innovations1,
    imgMarginTop: 445,
    desc: (
      <div>
        <p>WHY DRY CLEAN</p>
        <p>
          Challenge the rules with a gentle washing machine from AEG and confidently wash even your most delicate
          outfits. And care for the environment with the way you care for your clothes. Why compromise on a washing
          machine that doesn’t live up to your standards?{' '}
        </p>
        <p>Challenge the expected.</p>
      </div>
    ),
  },
  {
    title: 'WASHING MACHINES WITH STEAM',
    src: innovations2,
    imgMarginTop: 445,
    offsetTop: -50,
    desc: (
      <div>
        <p>WHY WASH WHEN YOU CAN STEAM</p>
        <p>
          Redefine the rules and simply refresh your clothes. By steaming instead of washing you can save more than 40
          litres of water, and care for the environment while caring for your wardrobe. Why compromise on washing habits
          that don’t live up to your life.
        </p>
        <p>Challenge the expected.</p>
      </div>
    ),
  },
  {
    title: 'ABSOLUTECARE TUMBLE DRYER',
    src: innovations3,
    imgMarginTop: 445,
    offsetTop: -50,
    desc: (
      <div>
        <p>DO TUMBE DRY</p>
        <p>
          Forget the rules with AbsoluteCare and tumble dry everything you wash (yes, even that wool sweater). Without
          worrying about shrinkage or damage. Why compromise on how you do laundry that doesn’t live up to your life.
        </p>
        <p>Challenge the expected.</p>
      </div>
    ),
  },
  {
    title: 'AUTODOSE WASHING MACHINES',
    src: innovations4,
    imgMarginTop: 445,
    offsetTop: -50,
    desc: (
      <div>
        <p>WHY DEAL WITH DETERGENT</p>
        <p>
          Forget dealing with detergent dosage. Get it right every time with AutoDose and protect your clothes from fade
          or age, while also caring for the environment. Why compromise with your wardrobe when there’s so much more to
          life.
        </p>
        <p>Challenge the expected.</p>
      </div>
    ),
  },
  {
    title: 'COMFORTLIFT DISHWASHER',
    src: innovations5,
    imgMarginTop: 445,
    offsetTop: -50,
    desc: (
      <div>
        <p>WHY NOT ON YOUR LEVEL</p>
        <p>
          Raise your standards – and the lower basket of your dishwasher to a more convenient level with ComfortLift®
          technology. And make your dishes come to you. Why compromise on a kitchen that doesn’t live up to your life.
        </p>
        <p>Challenge the expected.</p>
      </div>
    ),
  },
  {
    title: 'SENSEPRO HOB',
    src: innovations6,
    imgMarginTop: 445,
    desc: (
      <div>
        <p>WHY NOT PERFECT TO THE CORE</p>
        <p>
          Why guess when you can be precise about each degree with SensePro hob. The wireless food sensor measures inner
          temperature and communicates heat adjustments to the hob. So you can achieve perfect core result. Why
          compromise on appliances that don’t live up to your life.
        </p>
        <p>Challenge the expected.</p>
      </div>
    ),
  },
  {
    title: 'STEAMPRO OVEN WITH COMMAND WHEEL',
    src: innovations7,
    imgMarginTop: 445,
    offsetTop: -50,
    desc: (
      <div>
        <p>WHY DEAL WITH DEGREES</p>
        <p>
          Order from your SteamPro oven with command wheel the way you would at a restaurant. Select rare, medium or
          well done to enjoy perfect results at home without ever dealing with degrees. Why compromise on an oven that
          doesn’t live up to your standards.
        </p>
        <p>Challenge the expected.</p>
      </div>
    ),
  },
  {
    title: 'QX9 VACUUM CLEANER',
    src: innovations8,
    imgMarginTop: 445,
    desc: (
      <div>
        <p>WHO SAYS POWER CAN’T BE BEAUTIFUL</p>
        <p>
          Don’t hide your vacuum cleaner. With the cordless QX9 you can put power centre stage and clean with ease
          without compromising on style. Why settle for just performance when there’s so much more to life.
        </p>
        <p>Challenge the expected.</p>
      </div>
    ),
  },
]

export default class InnovationsSlider extends Component {
  state = {
    index: 0,
  }

  componentDidMount = () => {
    idleActions.set(185000)
    this.setInterval()
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  setInterval = () => {
    if (this.interval) clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.onNextSlide()
    }, 20000)
  }

  onChangeIndex = (index, e = null) => {
    if (e) e.stopPropagation()

    this.setState({ index }, () => {
      this.slider.slickGoTo(this.state.index)
      this.setInterval()
    })
  }

  onNextSlide = () => {
    const { index } = this.state

    if (index < slides.length - 1) {
      this.onChangeIndex(index + 1)
    } else {
      // this.onChangeIndex(0)
      this.props.history.push('/menu/innovations')
    }

    // idleActions.set()
  }

  onClick = () => {
    idleActions.set()
    this.onNextSlide()
  }

  render = () => {
    const { index } = this.state

    return (
      <div className={styles.container} onClick={this.onClick}>
        <Header title={slides[index].title} backTo="/menu/innovations" offsetTop={slides[index].offsetTop || 0} />

        <Slider
          infinite={false}
          swipe={true}
          className={styles.slider}
          arrows={false}
          afterChange={this.onChangeIndex}
          ref={node => (this.slider = node)}
        >
          {slides.map((slide, i) => (
            <div className={styles.slide} key={i}>
              <img src={slide.src} alt={slide.title} style={{ marginTop: slide.imgMarginTop || 0 }} />
              {slide.desc}
            </div>
          ))}
        </Slider>

        <Animated animationIn="fadeInUp" animateOnMount animationInDelay={500}>
          <div className={styles.sliderDots}>
            {slides.map((slide, i) => (
              <div
                className={cn(index === i && styles.activeDot)}
                onClick={e => {
                  this.onChangeIndex(i, e)
                  idleActions.set()
                }}
                key={i}
              />
            ))}
          </div>
        </Animated>
      </div>
    )
  }
}
