import React, { Component } from 'react'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'
import Slider from 'react-slick'
import cn from 'classnames'
import { VIDEOS } from 'containers/Home'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import theCareLabelProject from 'sources/videos/TheCareLabelProject.mp4'
import careLabel1preview from 'sources/images/care-label-1.jpg'
import careLabel2 from 'sources/images/care-label-2.jpg'
import careLabel3 from 'sources/images/care-label-3.jpg'
import tasteology from 'sources/videos/Tasteology.mp4'
import careLabel4preview from 'sources/images/care-label-4.jpg'
import careLabel5 from 'sources/images/care-label-5.jpg'
import careLabel6 from 'sources/images/care-label-6.jpg'
import careLabel7 from 'sources/images/care-label-7.jpg'
import careLabel8 from 'sources/images/care-label-8.jpg'

export default class InnovationsSlider extends Component {
  state = {
    index: 0,
    video: false,
  }
  slides = [
    {
      title: 'The Care Label Project',
      offsetTop: -50,
      video: VIDEOS.video.theCareLabelProject || theCareLabelProject,
      preview: careLabel1preview,
      desc: (
        <p>
          We want to break today’s care habits.
          <br />
          <br />
          Why? 90% of the clothes we own are thrown away long before they should be, and we still insist on using
          outdated care instructions from the 1950s. A change is needed.
        </p>
      ),
    },
    {
      title: 'The Care Label Project',
      offsetTop: -50,
      src: careLabel2,
      imageClassName: styles.redImage,
      desc: (
        <p>
          The Care Label Project is our way as appliance makers, designers and manufacturers to inspire, educate and
          update the way we all care for our clothes. We have to stop labelling our delicate fabrics with "Dry Clean
          Only", stop washing in high temperatures and stop being afraid of putting wool in our machines. In fact, the
          very care labels that are supposed to guide us often make us more confused.
        </p>
      ),
    },
    {
      title: 'The Care Label Project',
      offsetTop: -50,
      src: careLabel3,
      imageClassName: styles.whiteImage,
      desc: (
        <p>
          Don’t Overwash
          <br />
          <br />
          With The Care Label Project, we're turning this around. To start, we’ve launched a completely new care label -
          "Don't Overwash" - alongside an exclusive collection of designer garments with lab-tested care advice and a
          modern fabric care guide. Join us in changing the future of fashion, one care label at a time.
        </p>
      ),
    },
    {
      title: 'TASTEOLOGY',
      video: VIDEOS.video.tasteology || tasteology,
      preview: careLabel4preview,
      desc: (
        <p>
          Tasteology is a new documentary uncovering the four steps of how to achieve cooking results that are
          multisensory, sustainable, nutritional and tasteful all at once.
        </p>
      ),
    },
    {
      title: 'TASTEOLOGY',
      src: careLabel5,
      desc: (
        <p className={styles.tasteology}>
          STEP 1: SOURCE
          <br />
          <br />
          By understanding and focussing on the source of your food, you can discover some of the tastiest, healthiest
          and most sustainable ways to take taste further. We're looking beyond the kitchen and starting at the true
          source. From the soils and climates that nourish your fruits and vegetables. And the lifestyles of the animals
          that produce your meat and dairy.
        </p>
      ),
    },
    {
      title: 'TASTEOLOGY',
      src: careLabel6,
      desc: (
        <p className={styles.tasteology}>
          STEP 2: CHILL
          <br />
          <br />
          Proper refrigeration improves the taste of your dishes before you've even started cooking. Fruit tastes
          fresh-off-the-vine, meat is always tender and leftovers taste just as delicious as a freshly prepared meal.
          Understanding food storage can ensure every ingredient is as delicious as possible. So you can take taste
          further both before and after you cook.
        </p>
      ),
    },
    {
      title: 'TASTEOLOGY',
      src: careLabel7,
      desc: (
        <p className={styles.tasteology}>
          STEP 3: HEAT
          <br />
          <br />
          Understanding why heat creates taste and texture allows you to explore the potential of every ingredient.
          Cooking creates taste by breaking down large molecules into smaller ones, releasing flavour locked inside
          them. Discover how different heating methods, from induction heating to steam and sous vide, unlock delicious
          flavours and take taste further in every dish.
        </p>
      ),
    },
    {
      title: 'TASTEOLOGY',
      src: careLabel8,
      desc: (
        <p className={styles.tasteology}>
          STEP 4: EXPERIENCE
          <br />
          <br />
          All of our senses can complement taste. So even when you've finished cooking, understanding how sights, sounds
          and tactile sensations affect flavour can help you continue taking taste further all the way to the table.
        </p>
      ),
    },
  ]

  componentDidMount = () => {
    idleActions.unset()
    this.setInterval()
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  setInterval = () => {
    if (this.interval) clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.onNextSlide()
    }, 20000)
  }

  onChangeIndex = (index, e = null) => {
    if (e) e.stopPropagation()

    this.setState({ index }, () => {
      if (index !== 0 && index !== 3) {
        this.refs[`video-0`].pause()
        this.refs[`video-3`].pause()
        this.setState({ video: false })
      }

      this.slider.slickGoTo(this.state.index)
      this.setInterval()
    })
  }

  onNextSlide = () => {
    const { index } = this.state

    if (index < this.slides.length - 1) {
      this.onChangeIndex(index + 1)
    } else {
      // this.onChangeIndex(0)
      this.props.history.push('/menu/innovations')
    }
  }

  onClick = () => {
    idleActions.set()
    this.onNextSlide()
  }

  onEnded = () => {
    this.setState({ video: false })
  }

  onClickVideo = e => {
    e.preventDefault()
    e.stopPropagation()

    const { video, index } = this.state

    if (!video) {
      this.refs[`video-${index}`].play()
      idleActions.unset()
      clearInterval(this.interval)
    } else {
      this.refs[`video-${index}`].pause()
      idleActions.set()
      this.setInterval()
    }

    this.setState({ video: !video })
  }

  render = () => {
    const { index, video } = this.state

    return (
      <div className={styles.container} onClick={this.onClick}>
        <Header
          title={this.slides[index].title}
          offsetTop={this.slides[index].offsetTop || 0}
          backTo="/menu/innovations"
        />

        <Slider
          infinite={false}
          swipe={true}
          className={styles.slider}
          arrows={false}
          afterChange={this.onChangeIndex}
          ref={node => (this.slider = node)}
        >
          {this.slides.map((slide, i) => (
            <div className={styles.slide} key={i}>
              {slide.src && (
                <div className={cn(slide.imageClassName)}>
                  <img src={slide.src} className={styles.image} alt={slide.title} />
                </div>
              )}
              {slide.video && (
                <div className={cn(styles.videoWrapper, !video && styles.videoStopped)} onClick={this.onClickVideo}>
                  <video className={styles.video} ref={`video-${i}`} onEnded={this.onEnded} poster={slide.preview}>
                    <source src={slide.video} type="video/mp4" />
                  </video>
                </div>
              )}

              {slide.desc}
            </div>
          ))}
        </Slider>

        <Animated animationIn="fadeInUp" animateOnMount animationInDelay={500}>
          <div className={styles.sliderDots}>
            {this.slides.map((slide, i) => (
              <div
                className={cn(index === i && styles.activeDot)}
                onClick={e => {
                  this.onChangeIndex(i, e)
                  idleActions.set()
                }}
                key={i}
              />
            ))}
          </div>
        </Animated>
      </div>
    )
  }
}
