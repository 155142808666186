import history from 'utils/history'

let timeOut = null

export function set(ms = 120000) {
  unset()

  console.log('set idle')
  timeOut = setTimeout(() => {
    history.push('/')
  }, ms)
}

export function unset() {
  console.log('unset idle')
  clearTimeout(timeOut)
}
