import React, { Component } from 'react'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'
import Slider from 'react-slick'
import cn from 'classnames'
import { VIDEOS } from 'containers/Home'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import demographics from 'sources/images/new-demographics.jpg'
import lustForLife from 'sources/images/lust-for-life.jpg'
import leadAndSucceed from 'sources/images/new-lead-and-succeed.jpg'
import goingPlaces from 'sources/images/new-going-places.jpg'
// import lustForLife from 'sources/videos/LustForLife.mp4'

export default class WhoLivesHereMore extends Component {
  state = {
    index: 0,
  }
  slides = [
    {
      title: 'Demographics',
      src: demographics,
      desc: (
        <p>
          They belong to the <strong>upper/upper middle class.</strong>
          <br />
          The majority is <strong>well educated</strong>, many hold senior positions such as Director or Manager.
          Professions include finance, facilities, IT and project management.
          <br />
          <br />
          They have a <strong>high income</strong> (+€50,000) and thus possess high <strong>purchasing power</strong>.
          <br />
          <br />
          They live in city centers or large towns.
        </p>
      ),
    },
    {
      title: 'LUST FOR LIFE',
      src: lustForLife,
      desc: (
        <p>
          Ambitious Achievers are outgoing, confident people who embrace life to the fullest. They like to be the center
          of attention and to excel, and so they look for places, moments and experiences which help them do this. They
          project an infectious joy of life which attracts others to them. Looking for settings that enable an active,
          dynamic lifestyle, they tend to live in city centers and large towns.
        </p>
      ),
    },
    {
      title: 'LEAD AND SUCCEED',
      src: leadAndSucceed,
      desc: (
        <p>
          Driven and always on the go, they’re well educated, and hold leading positions both professionally and
          privately. Being successful, and being seen as successful by their peers, is important to them. This, in
          combination with a healthy competitiveness, pushes them to continuously extend their frame of reference, and
          to learn and master new things.
        </p>
      ),
    },
    {
      title: 'GOING PLACES',
      src: goingPlaces,
      desc: (
        <p>
          Being in an expansive and fast-moving phase of life, they have a real sense of moving forward towards their
          goals. Their home reflects this momentum and they like to show it off; it’s never just a place to live. They
          love to have friends and family over for dinners and parties and will put their heart and soul into creating
          memorable experiences for their loved ones. Very comfortable with new technology, they are always on the
          lookout for the latest and the best with the aim to further their skills and improve results.
        </p>
      ),
    },
  ]

  componentDidMount = () => {
    idleActions.set()
    this.setInterval()
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  setInterval = () => {
    if (this.interval) clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.onNextSlide()
    }, 15000)
  }

  onChangeIndex = (index, e = null) => {
    if (e) e.stopPropagation()

    this.setState({ index }, () => {
      this.slider.slickGoTo(this.state.index)
      /*if (index !== 1) {
        this.setInterval()
        this.refs.video.pause()
        this.refs.video.currentTime = 0
      } else {
        this.setInterval()
        idleActions.unset()
        this.refs.video.play()
      }*/
    })
  }

  onNextSlide = () => {
    const { index } = this.state

    if (index < this.slides.length - 1) {
      this.onChangeIndex(index + 1)
    } else {
      // this.onChangeIndex(0)
      this.props.history.push('/menu/who-lives-here')
    }
  }

  onClick = () => {
    idleActions.set()
    this.onNextSlide()
  }

  render = () => {
    const { index } = this.state

    return (
      <div className={styles.container} onClick={this.onClick}>
        <Header
          title={this.slides[index].title}
          backTo="/menu/who-lives-here"
          offsetTop={this.slides[index].offsetTop || 0}
        />

        <Slider
          infinite={false}
          swipe={true}
          className={styles.slider}
          arrows={false}
          afterChange={this.onChangeIndex}
          ref={node => (this.slider = node)}
        >
          {this.slides.map((slide, i) => (
            <div className={styles.slide} key={i}>
              {slide.src && <img src={slide.src} alt={slide.title} />}
              {slide.video && <video src={slide.video} ref="video" loop />}

              {slide.desc}
            </div>
          ))}
        </Slider>

        <Animated animationIn="fadeInUp" animateOnMount animationInDelay={500}>
          <div className={styles.sliderDots}>
            {this.slides.map((slide, i) => (
              <div
                className={cn(index === i && styles.activeDot)}
                onClick={e => {
                  this.onChangeIndex(i, e)
                  idleActions.set()
                }}
                key={i}
              />
            ))}
          </div>
        </Animated>
      </div>
    )
  }
}
