import React, { useEffect } from 'react'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import brandIdea from 'sources/images/new-brand-idea.jpg'

export default function BrandIdea(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header backTo="/menu/our-brand/who-we-are" title="BRAND PROMISE" />

      <img src={brandIdea} className={styles.brandIdea} alt="Brand idea" />

      <p className={styles.text}>
        <b>CHALLENGE THE EXPECTED</b>
        <br />
        <br />
        With a distinctive and sophisticated cool edge, we provide tools and services that challenge what is expected of
        the industry. We elevate the everyday and take your skills further to master exceptional taste, care and comfort
        of your life at home through uncompromising and responsible innovation.
      </p>
    </div>
  )
}
