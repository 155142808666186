import React, { useEffect } from 'react'
import Header from 'components/Header'
import { VIDEOS } from 'containers/Home'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import brandVideo from 'sources/videos/BrandVideo.mp4'

export default function BrandVideo(props) {
  useEffect(() => {
    idleActions.unset()
  }, [])

  const onEnded = () => {
    props.history.goBack()
  }

  return (
    <div className={styles.container}>
      <Header backTo="/menu/our-brand" title="BRAND VIDEO" />

      <video className={styles.video} src={VIDEOS.video.brandVideo || brandVideo} onEnded={onEnded} autoPlay />
    </div>
  )
}
