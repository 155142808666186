import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import intelligentTechnology from 'sources/images/intelligent-technology.jpg'

export default function WhoLivesHere(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header backTo="/menu/innovations" title="Intelligent technology and responsive design" offsetTop={-100} />

      <img src={intelligentTechnology} className={styles.image} alt="Intelligent technology" />

      <p className={styles.desc}>
        Developing products that respond and adapt to the way you want to use them, instantly reacting and responding by
        providing exactly the right function at the right setting at the right time.
        <br />
        <br />
        For Example...
        <br />
        <br />
        <ul className={styles.list}>
          <li>
            OKOMIX washing machines detect fabrics and adjust settings to provide excellent care for all your clothes
          </li>
          <li>
            The AEG MaxiSense Pure with Flexibridge hob allows you to cook your favourite dishes using cookware of any
            size and shape, instantly reacting to your needs as you move them about the surface
          </li>
        </ul>
        <br />
        <br />
        <br />
        <Link to="/menu/innovations/innovations/more">
          Explore more <span className="fa">&#xf054;</span>
        </Link>
      </p>
    </div>
  )
}
