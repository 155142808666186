import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'

export default function OurBrand(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header backTo="/menu" title="OUR BRAND" />

      <span className={styles.str}>CHALLENGE THE EXPECTED</span>

      <Animated animationIn="fadeInUp" animateOnMount animationInDelay={500}>
        <ul className={styles.menu}>
          <li>
            <Link to="/menu/our-brand/who-we-are">Who we are</Link>
          </li>
          <li>
            <Link to="/menu/our-brand/brand-attributes">What we stand for</Link>
          </li>
          <li>
            <Link to="/menu/our-brand/brand-video">Brand video</Link>
          </li>
          <li>
            <Link to="/menu/our-brand/taste">Taste, Care and Wellbeing</Link>
          </li>
          <li>
            <Link to="/menu/our-brand/brand-manifesto">Brand manifesto</Link>
          </li>
        </ul>
      </Animated>
    </div>
  )
}
